import styles from './styles.module.css';

const Titulo = (props) => {
    
    const {
        text,
        link,
        color,
    } = props;
    
    return (
        <div className={`${styles['t-container']}`}>
             {
                link ? 
                    <h2 className={`${styles['text']}`} style={{ color: color }}>
                        <a href={link} target={'_blank'} rel='noreferrer noopener'>
                        {text}
                        </a>
                    </h2>
                :
                    <h2 className={`${styles['text']}`} style={{ color: color }}>{text}</h2>
             }
        </div>
    )
}

export default Titulo;